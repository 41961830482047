<template>
  <div v-if="loading" class="w-auto h-full display-block">
<!--    <a-loader class="display-block h-16 w-16"/>-->
  </div>
  <div v-if="!linkError">
    <div class="flex flex-col items-center" :class="{ 'pb-44': isAuthenticated, 'pb-28': !isAuthenticated }">
      <div class="pt-4 mb-2 mx-auto">
        <div>
          <a-initials
            :name="values.name"
            :src="values.profile_picture"
            :desing-class="'h-20 w-20 block mx-auto'"
            @click="$router.replace({ name: 'Login' })"/>
        </div>
        <h1 class="text-black" style="font-size: 20px;">{{ '@' + values?.ig_username }}</h1>
        <!-- <span> Reserva en los últimos restaurantes que he visitado</span> -->
      </div>
      <div class="pb-2">
        <h2 class="text-black px-8">
          Descubre los últimos sitios que he publicado. <b>¡Reserva aquí!</b>
        </h2>
      </div>
      <ul class="w-full py-2 space-y-3 max-w-sm px-8 md:px-2">
        <public-sololink-cell
          v-for="(link, key) in links"
          :key="key"
          :sololink="link"
          :isEditing="false"
          @reservation="reserveModal"
          @remove="removeSololink(key)" />
      </ul>
      <div class="mt-normal" v-if="sololinks.length > 4">
        <a href="#" class="mx-auto text-primary" @click="more = !more">
          {{ more ? 'Ocultar' : 'Ver todos' }}
        </a>
      </div>
      <div v-if="!user.user.isFoodie" class="fixed text-center mx-auto w-full bg-white py-2" :class="{ 'bottom-14': isAuthenticated, 'bottom-0': !isAuthenticated }">
        <p class="inline-block w-full mt-2">¿Quieres que visite tu restaurante?</p>
        <button @click="redirect" class="btn_send_primary bg-primary mt-4 disabled:bg-gray" :disabled="loadingBooking">
          <a-loader v-if="loadingBooking" class="h-6 w-6 mx-auto block" :color="'text-white'" />
          <span v-else>
          <i class="icon-colaborate" />
          ¡Colaboremos!
        </span>
        </button>
      </div>
    </div>
  </div>

  <div v-else>
    <div>
      <div class="flex flex-col items-center" :class="{ 'pb-44': isAuthenticated, 'pb-28': !isAuthenticated }">
        <div class="pt-4 mb-2 mx-auto w-full">
          <logo-1 class="w-full"/>
          <h1 class="text-black mt-4 text-xxl" >{{ '@' + $route.params.igusername }}</h1>
        </div>
        <div class="pb-2">
          <h2 class="text-black px-8 text-lg mt-4">
            Revisa el nombre del foodie. <b>{{ '@' + $route.params.igusername }}</b> no existe.
          </h2>
        </div>
        <div v-if="!user.user.isFoodie" class="fixed text-center mx-auto w-full bg-white py-2" :class="{ 'bottom-14': isAuthenticated, 'bottom-0': !isAuthenticated }">
          <button @click="redirect()" class="btn_send_primary bg-primary mt-4 disabled:bg-gray">
            <span>
              <i class="icon-colaborate" />
              Volver
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <booking
    solo-link
    v-if="selectedLink !== null"
    v-model:show="modal"
    v-model:comment="form.comment"
    :restaurant="selectedLink.restaurant"
    :loading-reservation="loadingBooking"
    :reservation-form="{
        date: bookingFormValues.date,
        people_amount: bookingFormValues.people_amount,
        comment: form.comment,
        time: bookingFormValues.time,
        zone: bookingFormValues.zone
    }"
    :completed="completed"
    @validated-date="setReservationValues"
    @confirm-booking="reservation"
  />
    <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveError = false"
    :show="reserveError">
    <h2 class="text-lg text-red-400 text-center">
      Ha ocurrido un error
    </h2>
    <p class="text-normal text-center mt-4">
      No se ha podido crear la reserva.
    </p>
    <p class="text-lg text-center mt-4">
      {{ reserveErrorMessage }}
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveSuccess = false"
    :show="reserveSuccess">
    <h2 class="">
      ¡Enhorabuena!
    </h2>
    <p class="text-lg">
      Su reserva ha sido recibida. En breve recibirá confirmación vía email
    </p>
  </a-alert>
  <a-alert
    disabled-overlay-dismiss
    @cancel="redirectModal = false"
    @confirm="redirectLink"
    :show="redirectModal">
    <h2 class="text-center text-lg">
      ¡Atención!
    </h2>
    <p class="text-lg">
      Se abrirá una nueva pestaña para realizar su reserva.
    </p>
  </a-alert>
</template>

<script>
import { ref, onMounted, computed, reactive, watch } from 'vue'
import { useArray, useRepository, useSession, useErrors } from '../../../hooks'
import { useRoute, useRouter } from 'vue-router'
import PublicSololinkCell from './public-sololink-cell.vue'
// import Mask from 'primevue/inputmask'
import Booking from '@/components/booking/booking-card'
import Logo1 from '@/components/logos/logo-1'

export default {
  components: {
    Logo1,
    Booking,
    PublicSololinkCell
    // Mask,
  },
  data () {
    return {
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      modals: {
        reservation: false
      }
    }
  },
  setup () {
    const more = ref(false)
    const defaultLogo = require('@/assets/logo.png')
    const linkError = ref(false)
    // const $filters = inject('$filters', {})
    const values = ref({})
    const loading = ref(false)
    const modal = ref(false)
    const reserveError = ref(false)
    const checkBookingFail = ref(false)
    const reserveErrorMessage = ref(false)
    const reserveSuccess = ref(false)
    const loadingBooking = ref(false)
    const bookingFail = ref(false)
    // const booking = useRepository(({ bookings }) => bookings)
    const bookingHours = ref([])
    const type = ref('')
    const selectedLink = ref(null)
    const getUserByIGUsername = useRepository(({ users }) => users.igUsername)
    const setReservation = useRepository(({ bookings }) => bookings.sololinks)
    const sololinksRepository = useRepository(({ sololinks }) => sololinks)
    const { params } = useRoute()
    const router = useRouter()
    const people = ref(0)
    const { isAuthenticated } = useSession()
    const user = useSession()
    const [sololinks, { set: setSololinks, remove: removeSololink }] = useArray()
    const selectedHours = ref('00:00')
    const selectedMinutes = ref('00')
    const bookingProvider = ref(false)
    const date = ref(null)
    const form = reactive({ date: '', comment: '', solo_link_id: '' })
    const client = reactive({ first_name: '', last_name: '', email: '', phone: '' })
    const peopleAmount = ref(2)
    const peopleLength = ref(0)
    const dateList = ref([])
    const completed = ref(false)
    const redirectModal = ref(false)

    const bookingFormValues = ref({})
    const bookingValues = ref(false)

    const fetch = () => {
      loading.value = !loading.value
      loadingBooking.value = true
      getUserByIGUsername(params.igusername)
        .then(({ data }) => {
          if (data.type !== 'foodie') {
            return router.push({ name: 'Login' })
          }
          linkError.value = false
          values.value = data.object

          setSololinks(data.solo_links.sort((a, b) => a.created_at > b.created_at ? 1 : -1))
        })
        .catch(() => {
          linkError.value = true
        })
        .finally(() => {
          loading.value = !loading.value
          loadingBooking.value = false
        })
    }
    const reservation = (event) => {
      bookingProvider.value = selectedLink.value.restaurant.covermanager_id !== null

      const newForm = {
        date: bookingFormValues.value.date,
        comment: event.comment,
        solo_link_id: selectedLink.value.id,
        use_booking_provider: bookingProvider.value,
        people_amount: parseInt(bookingFormValues.value.people_amount),
        zone: bookingFormValues.value.zone,
        client: {
          first_name: event.first_name,
          last_name: event.last_name,
          email: event.email,
          phone: '+34' + event.phone.replace(/\D/g, '')
        }
      }
      loadingBooking.value = true
      setReservation(selectedLink.value.id)
        .store({ ...newForm })
        .then(() => {
          reserveSuccess.value = true
        })
        .finally(() => {
          loadingBooking.value = false
          modal.value = false
          // completed.value = true
        })
        .catch(({ response }) => {
          reserveError.value = true
          modal.value = false
          date.value = ''
          reserveErrorMessage.value = useErrors(response?.data?.error_code)
          // switch (response?.data?.error_code) {
          //   case 'BK001': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK002': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK003': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK004': {
          //     reserveErrorMessage.value = 'Las reservas no estan disponible para este restaurante'
          //     break
          //   }
          //   case 'BK005': {
          //     reserveErrorMessage.value = 'No se pudo crear la reserva'
          //     break
          //   }
          //   case 'BK006': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK007': {
          //     reserveErrorMessage.value = 'Ya tienes unas reserva con este restaurante'
          //     break
          //   }
          //   case 'BK008': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   default: {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          // }
        })
    }

    const setReservationValues = (values) => {
      bookingFormValues.value = values
      bookingValues.value = true
    }

    const links = computed(() => (more.value ? sololinks.value : sololinks.value.slice(0, 4)))

    const redirect = () => {
      if (user?.user?.restaurant?.id) {
        router.push({ name: 'explore.show', params: { id: values.value.id } })
      } else {
        // router.push({ name: 'Login', query: { collaboration_with: values.value.id } })
        router.push({ name: 'Welcome' })
      }
    }

    const reserveModal = (soloLink) => {
      if (!isAuthenticated) {
        selectedLink.value = soloLink
        if (selectedLink.value.restaurant.covermanager_id) {
          modal.value = true
          selectedLink.value = soloLink
        } else {
          // redirectModal.value = true
          sololinksRepository
            .externalBookingVisit(selectedLink.value.id)
            .then(() => {
              loadingBooking.value = false
            })
            .finally(() => {
              window.location.replace(selectedLink.value.restaurant.settings.bookings_external_link)
              // redirectModal.value = false
            })
          // window.open(soloLink.restaurant.settings.bookings_external_link)
          // loadingBooking.value = true
        }
        // selectedHours.value = selectedLink?.value?.slim_restaurant?.covermanager_id ? '00:00' : '00'
      }
    }

    const redirectLink = () => {
      loadingBooking.value = true
      sololinksRepository
        .externalBookingVisit(selectedLink.value.id)
        .then(() => {
          loadingBooking.value = false
        })
        .finally(() => {
          window.open(selectedLink.value.restaurant.settings.bookings_external_link, '_blank')
          redirectModal.value = false
        })
    }

    watch(modal, (value) => {
      if (!value) {
        selectedLink.value = null
      }
    })

    onMounted(() => {
      fetch()
    })

    return {
      user,
      defaultLogo,
      values,
      sololinks,
      selectedLink,
      links,
      loading,
      modal,
      people,
      reserveError,
      reserveErrorMessage,
      reserveSuccess,
      type,
      more,
      isAuthenticated,
      checkBookingFail,
      peopleAmount,
      loadingBooking,
      client,
      form,
      selectedHours,
      selectedMinutes,
      date,
      bookingProvider,
      bookingFail,
      bookingHours,
      peopleLength,
      dateList,
      bookingValues,
      bookingFormValues,
      completed,
      linkError,
      redirectModal,
      fetch,
      redirect,
      reservation,
      removeSololink,
      reserveModal,
      setReservationValues,
      redirectLink
    }
  },
  methods: {
    addPeople (value) {
      if (value === 'add') {
        this.peopleAmount += 1
      } else {
        if (this.peopleAmount > 1) {
          this.peopleAmount -= 1
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main_bg_gray{
  background: #FAFAFA;
  height: 100vh;
}
.btn_send_primary{
  display:inline-block;
  color:white;
  height: 44px;
  width: 255px;
  border-radius: 44px;
}

.colab_list{
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  //padding: 10px 10px 0px 10px;
  li{
    background: white;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    line-height: 58px;
    width: 100%;
    margin-bottom: 10px;
    .avatar_img{
      width: 48px;
      height: 48px;
    }
    b{
      width: calc(100% - 78px);
      display: inline-block;
      line-height: 1.1;
      vertical-align: middle;
      text-align: left;
      padding-left: 10px;
    }
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
}
//#phone{
//  height: 48px !important;
//  border: 1px solid #d1d1d1;
//  border-radius: 6px !important;
//  padding: 0 10px;
//  line-height: 48px;
//  width: 100%;
//  display: inline-block;
//  vertical-align: middle;
//  text-align: left !important;
//  font-size: 14px !important;
//}
//.p-inputtext{
//  background-color: #fff !important;
//}
.p-inputmask{
  background-color: white !important;
  border: none;
  text-align: left !important;
  font-size: 16px !important;
  padding: 0 !important;
}
.p-inputmask:enabled:focus {
  /*border: none !important;*/
  box-shadow: 0 0 0 0 #23BB2D !important;
  border: none;
  border-radius: 0;
}
.p-inputtext{
  border-radius: 0 !important;
}
#phone-container{
  height: 48px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  padding: 0 10px;
  line-height: 48px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
}
</style>
